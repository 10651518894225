import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

const StyledContactUs = styled.div`
  padding: 20px;
  color: black;
  text-align: center;

  ${media.lessThan('medium')`
    padding: 10px;
  `}
`;

const StyledH1 = styled.h1`
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  font-size: 48px;
  color: maroon;

  ${media.lessThan('medium')`
    font-size: 36px;
  `}
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;

  ${media.lessThan('medium')`
    padding: 10px;
  `}
`;

const StyledInput = styled.input`
  width: 60%;
  padding: 10px;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: maroon;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: darkred;
  }
`;



function Contact() {


  return (
    <StyledContactUs>
        {/* Add this hidden form */}
        <StyledH1>Contact Us</StyledH1>
        <StyledForm name="contact" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="contact" />
          <StyledInput type="text" name="name" placeholder="Your Name" required />
          <StyledInput type="email" name="email" placeholder="Your Email" required />
          <StyledInput type="text" name="subject" placeholder="Subject" required />
          <StyledInput as="textarea" name="message" placeholder="Your Message" required />
          <StyledButton type="submit">Send</StyledButton>
        </StyledForm>


    </StyledContactUs>
  );
}

export default Contact;
