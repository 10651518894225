import React from 'react';
import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';

// Import your images
import beginnerImg from './img8.png';
import intermediateImg from './img9.png';
import advancedImg from './img10.png';

const customMedia = generateMedia({
  desktop: '78em',
  tablet: '60em',
  mobile: '46em'
});

const ProgramsWrapper = styled.div`
  display: flex;  
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;  // Horizontally center items
  align-items: center;  // Vertically center items
  padding-top: 20px;  // Creates space at the top of the container
  
  ${customMedia.lessThan('mobile')`
    flex-direction: column;
    align-items: center;  // Vertically center items in mobile view as well
  `}
`;


const ProgramCard = styled.div`
  position: relative;  // Needed to position the text over the image
  width: 300px;  // Adjust as needed
  margin: 30px;

  img {
    width: 100%;  // Make the image take up the full width of the ProgramCard
  }

  h2 {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  p {
    position: absolute;
    bottom: 70px;
    left: 10px;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .5);
    
    &:last-of-type {
      bottom: 30px;
    }
  }
`;


function Programs() {
  return (
    <ProgramsWrapper>
      <ProgramCard>
        <img src={beginnerImg} alt="Beginner Program" />
        <h2>Drop-In Sessions</h2>
        <p>$40 per session</p>
        <p>*Call in advance to check availability</p>
      </ProgramCard>
      <ProgramCard>
        <img src={intermediateImg} alt="Intermediate Program" />
        <h2>Monthly Membership</h2>
        <p>$250 per month</p>
        <p>*Includes 2 jump sessions per week</p>
      </ProgramCard>
      <ProgramCard>
        <img src={advancedImg} alt="Advanced Program" />
        <h2>6-Week Lifting Program</h2>
        <p>$150 for the program</p>
        <p>*Specifically designed for pole vaulters</p>
      </ProgramCard>
    </ProgramsWrapper>
  );
}

export default Programs;

