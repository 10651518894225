import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import image1 from './IMG_3804.jpg';
import image2 from './img2.jpg';
import image3 from './img3.jpg';
import image4 from './img4.jpg';
import image5 from './img5.jpg';
import image6 from './img6.jpg';

const StyledHome = styled.div`
  padding: 20px;
  color: black;
  text-align: center;

  ${media.lessThan('medium')`
    padding: 10px;
  `}
`;

const StyledH1 = styled.h1`
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  font-size: 48px;
  color: maroon;

  ${media.lessThan('medium')`
    font-size: 36px;
  `}
`;

const ImageRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;

  ${media.lessThan('medium')`
    flex-direction: column;
    align-items: center;
    padding: 10px;
  `}
`;

const StyledImg = styled.img`
  width: 300px;
  height: 300px;
  object-fit: cover;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

function Home() {
  return (
    <StyledHome>
      <StyledH1>Welcome to Pole Vault University</StyledH1>
      <p>We are a premier pole vaulting club in Nashville, Tennessee, dedicated to helping athletes reach new heights in the exciting sport of pole vaulting.</p>
      
      <ImageRow>
        <StyledImg src={image1} alt="Description of image 1" />
        <StyledImg src={image2} alt="Description of image 2" />
        <StyledImg src={image3} alt="Description of image 3" />
      </ImageRow>
      
      <ImageRow>
        <StyledImg src={image4} alt="Description of image 4" />
        <StyledImg src={image5} alt="Description of image 5" />
        <StyledImg src={image6} alt="Description of image 6" />
      </ImageRow>
    </StyledHome>
  );
}

export default Home;


