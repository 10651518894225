import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import styled from 'styled-components';

import Home from './Home';
import About from './About';
import Programs from './Programs';
import Donations from './Donations';
import Resources from './Resources';
import Contact from './Contact';
import logo from './pvuFullLogo.png';

const StyledNav = styled.nav`
  background: #282c34;
  padding: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin-right: 10px;
  
  &:hover {
    color: maroon;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: right;
  margin-left: 140px;
  align-items: center;
`;

const StyledImg = styled.img`
  width: 100px;
  height: 100px;
`;

function App() {
  return (
    <Router>
      <StyledNav>
        <StyledImg src={logo} />
        <LinksContainer>
          <StyledLink to="/">Home</StyledLink>
          <StyledLink to="/about">About</StyledLink>
          <StyledLink to="/programs">Programs</StyledLink>
          <StyledLink to="/donations">Donations</StyledLink>
          <StyledLink to="/resources">Resources</StyledLink>
          <StyledLink to="/contact">Contact</StyledLink>
        </LinksContainer>
      </StyledNav>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/donations" element={<Donations />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;

