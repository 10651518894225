import React from 'react';
import styled from 'styled-components';

const StyledAbout = styled.div`
  padding: 20px;
  color: black;
  font-size: 20px;
  text-align: left; 
  max-width: 800px;  /* Adjust this value as needed */
  margin: 0 auto;   /* This centers the div */

  
`;

const StyledH1 = styled.h1`
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  text-align: center; 
  font-size: 48px;
  color: maroon;
`;



function About() {
  return (
    <StyledAbout>
      <StyledH1>About Pole Vault University</StyledH1>
      <p>At Pole Vault University, our mission is to create an inclusive, accessible, and supportive environment that nurtures and empowers young pole vaulters. We aim to provide opportunities for high school students from all backgrounds, regardless of their financial means or prior experience in the sport.</p>
      <p>Our inspiration comes from the founder's personal experience in high school, where the lack of proper coaching and equipment could have been a barrier to pursuing pole vaulting. With the support of a dedicated family member who stepped in to coach the team and arrange equipment borrowing, the founder was able to reach new heights in the sport. We understand that not everyone has this kind of support, and that's why our main goal is to provide equitable vaulting opportunities for all.</p>
      <p>Pole Vault University is committed to using innovation to improve training methods and safety equipment. We believe that by investing in research and development, we can enhance the pole vaulting experience for our members while reducing the risk of injuries.</p>
      <p>We believe that everyone should have the chance to explore and develop their potential in pole vaulting. That's why we offer scholarship programs, low-cost club memberships, and free educational resources on our website, making it easier for students to access quality coaching, equipment, and learning materials.</p>
      <p>Our experienced and dedicated coaching staff is committed to helping each student reach their personal and athletic goals while also fostering a love for the sport and a strong sense of camaraderie among our members.</p>
      <p>Through a combination of structured training programs, personalized coaching, community engagement, and innovative approaches to equipment and safety, we strive to produce confident, skilled, and well-rounded athletes who will make a lasting impact in the world of pole vaulting.</p>
      <p>Together, we are Pole Vault University - where every student can soar to new heights, regardless of their starting point.</p>
      
    </StyledAbout>
  );
}

export default About;

