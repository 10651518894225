import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

const StyledDonations = styled.div`
  padding: 20px;
  color: black;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;

  ${media.lessThan('medium')`
    padding: 10px;
  `}
`;

const StyledH1 = styled.h1`
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  font-size: 48px;
  color: maroon;

  ${media.lessThan('medium')`
    font-size: 36px;
  `}
`;

const DonationLink = styled.a.attrs({
    target: "_blank",
    rel: "noopener noreferrer"
  })`
    display: inline-block;
    font-size: 20px;
    padding: 10px 20px;
    color: white;
    background-color: maroon;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
  
    ${media.lessThan('medium')`
      font-size: 18px;
      padding: 8px 16px;
    `}
  
    &:hover {
      background-color: black;
    }
`;


function Donations() {
  return (
    <StyledDonations>
      <StyledH1>Support Pole Vault University</StyledH1>
      <p>At Pole Vault University, we are committed to providing an inclusive, accessible, and supportive environment that nurtures and empowers young pole vaulters. Our main goal is to provide equitable vaulting opportunities for all, regardless of their financial means or prior experience in the sport.</p>
      <p>However, this important work isn't possible without generous support from our community. As a potential sponsor, your contribution will go directly towards helping us achieve our mission. This includes funding for scholarship programs, low-cost club memberships, improving training methods, enhancing safety equipment, and providing free educational resources on our website.</p>
      <p>With your help, we can ensure that every student, no matter their background, has the chance to explore and develop their potential in pole vaulting. Your support will not only help individual students reach new heights, but also contribute to the growth and development of the sport as a whole.</p>
      <p>We are grateful for any and all contributions, and we look forward to working with our sponsors to create a lasting impact in the world of pole vaulting. Together, we can make a difference. Thank you for considering supporting Pole Vault University.</p>
      <DonationLink href= 'https://buy.stripe.com/7sIeYtcWNdJzaTS6oo'>Donate Now</DonationLink>
    </StyledDonations>
  );
}

export default Donations;

