import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import character from './runguy.gif';

const StyledAbout = styled.div`
  padding: 20px;
  color: black;
  font-size: 20px;
  text-align: left; 
  max-width: 800px;  /* Adjust this value as needed */
  margin: 0 auto;   /* This centers the div */

  ${media.lessThan('medium')`
    padding: 10px;
  `}
`;

const StyledH1 = styled.h1`
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  text-align: center; 
  font-size: 48px;
  color: maroon;

  ${media.lessThan('medium')`
    font-size: 36px;
  `}
`;

const CharacterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Adjust as needed */

  ${media.lessThan('medium')`
    height: 200px;
  `}
`;

const CharacterImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const WordContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Adjust as needed */

  ${media.lessThan('medium')`
    height: 200px;
  `}
`;

function Resources() {
  return (
    <StyledAbout>
      <StyledH1>Resources</StyledH1>

      <CharacterContainer>
        <CharacterImage src={character} alt="Running 8-bit character" />
        
      </CharacterContainer>
      <WordContainer>This page will be full of fun information soon!</WordContainer>
    </StyledAbout>
  );
}

export default Resources;
